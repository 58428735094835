import { Show, Suspense } from 'solid-js';
import { useStripe } from '../../providers/stripe';
import { PaymentElement } from './elements/payment';
import type { PaymentWalletsOption } from '@stripe/stripe-js';

type Props = {
	wallets?: PaymentWalletsOption;
};

export function Elements(props: Props) {
	const { elements } = useStripe();

	return (
		<Suspense>
			<Show when={elements()}>{(elements) => <PaymentElement wallets={props.wallets} elements={elements()} />}</Show>
		</Suspense>
	);
}
